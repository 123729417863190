import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Header from '../components/Header'
import { StaticQuery, graphql } from 'gatsby';
import './index.css'
import '../assets/sass/grayscale.scss';

const Layout = ({hideHeader = false, hideChangeMode = false, children}) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title,
              heading
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'High quality ad-free news' },
              { name: 'keywords', content: 'tech, news, ad-free, business' },
            ]}
          >
            <html lang="en" />
          </Helmet>
          {hideHeader ? null : 
          <Header 
            headerTitle={data.site.siteMetadata.heading} 
            hideChangeMode={hideChangeMode}
          />}
          <div>{children}</div>
        </>
      )}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout