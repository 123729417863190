import React, { useContext } from 'react';
import { ThemeManagerContext } from "gatsby-styled-components-dark-mode"
import sun_white from '../assets/icons/outline_wb_sunny_white_24dp.png'
import moon_white from '../assets/icons/outline_bedtime_white_24dp.png'
import sun_black from '../assets/icons/outline_wb_sunny_black_24dp.png'
import moon_black from '../assets/icons/outline_bedtime_black_24dp.png'
import styled from 'styled-components'

const Nav = styled.nav`
  background: ${props => props.theme.background};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px 5px 10px;

  div {
    margin-left: 20px;
    font-size: 20px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  div {
    margin: 0;

    img {
      margin: 0;
      margin-left: 20px;
      padding-right: 20px;
    }
  }

`;

const Header = ({hideChangeMode = false, headerTitle}) => {
  const themeContext = useContext(ThemeManagerContext)
  return (
    <Nav
    >
      <div>
        <a  href="/">
          {headerTitle}
        </a>
      </div>
      {
        hideChangeMode ? null : 
        (
          <Container >
            <p>{themeContext.isDark}</p>
            {
              themeContext.isDark ? (
                <div>
                  <img 
                    src={sun_white}
                    alt={'sun icon'}
                    onClick={() => themeContext.toggleDark(false)} 
                    style={{
                      cursor: 'pointer'
                    }}
                  />
                  <img 
                    src={moon_white}
                    alt={'moon icon'}
                    onClick={() => themeContext.toggleDark(true)} 
                    style={{
                      cursor: 'pointer'
                    }}
                  />
                </div>
              ) : (
                <div>
                  <img 
                    src={sun_black}
                    alt={'sun icon'}
                    onClick={() => themeContext.toggleDark(false)} 
                    style={{
                      cursor: 'pointer'
                    }}
                  />
                  <img 
                    src={moon_black}
                    alt={'moon icon'}
                    onClick={() => themeContext.toggleDark(true)} 
                    style={{
                      cursor: 'pointer'
                    }}
                  />
                </div>
              )
            }
          </Container>
        )
      }
    </Nav>
  );
}

export default Header;